
import React from "react";
import { useTranslation } from "react-i18next"
import HeroImg from "../../components/HeroImg/HeroImg";
import LeftRightBox from "../../components/LeftRightBox/LeftRightBox";
import MainLayout from "../../layouts/MainLayout";
import "./about.scss";

const About = () => {
  const { t } = useTranslation()
  return (
    <MainLayout metaTitle={t("about:metaTitle")}>
      <>
        <HeroImg customClass="about-hero" subtitle={t("about:heroSubtitle")} title={t("about:heroTitle")} backgroundImage="bg/about-hero.jpg"></HeroImg>

        <div className="about">
          <div className="divider"></div>

          <div className="about-section-one">
            <LeftRightBox>
              {<div className="text-wrap">
                <h3 className="box-two">{t("about:sectionOneTitle")}</h3>
                <p className="box-two">{t("about:sectionOneText")}</p>
                <h4 className="box-two">{t("about:sectionOneTextTwo")}</h4>
              </div>}
              {<img src="/img/about-one.jpg" alt="about-one" />}
            </LeftRightBox>

            <div className="about-section-two">

              <LeftRightBox customClass="reverse">
                {<img src="/img/about-two.jpg" alt="about-two" />}
                {<>
                  <div className="text-wrap">
                    <h3 className="box-two">{t("about:sectionTwoTitle")}</h3>
                    <p className="box-two">{t("about:sectionTwoText")}</p>
                  </div>
                  <img src="/img/about-three.jpg" alt="about-three" />
                </>}

              </LeftRightBox>
            </div>

            <div className="about-section-three">
              <h3 className="box-two">{t("about:sectionThreeTitle")}</h3>
              <p className="box-two">{t("about:sectionThreeText")}</p>
              <div className="show-on-mobile">
                <img src="/img/about-three.jpg" alt="about-three" />
              </div>
            </div>
          </div>
        </div>
      </>
    </MainLayout>
  )
}

export default About;
